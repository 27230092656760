.no-mb {
	font-size: 0.875rem !important;
	line-height: 1.25rem !important;
	p {
		margin-bottom: 0.5rem !important;
	}
	p:last-of-type {
		margin-bottom: 0 !important;
	}
}
